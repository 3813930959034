<template>
  <div>
    <Header ref="WPheader" @selCat="selCat" @selPrice="selPrice"></Header>
    <Middle ref="goodsListContent" @initCart="initCart" msg="中国仓产品"></Middle>
  </div>
</template>

<script>
import Header from "../allGoods/header";
import Middle from "../allGoods/middle";

export default {
  components: {
    Header,
    Middle,
  },
  data: function() {
    return {};
  },
 created() {
    //添加分类
    setTimeout(async () => {
    await this.$refs.WPheader.getType();
      this.setGoodsTypeList()
    }, 500);
  },
  methods: {
    //设置价格
    selPrice(val) {
      this.$refs.goodsListContent.selPrice(val);
    },
    //设置
    selCat(value) {
      this.$refs.goodsListContent.setType(value.cls_no,value.type_field);
    },
    //初始化
    initCart() {
      this.$emit("initCart");
    },
    // 追加饰品分类
    setGoodsTypeList(){
      var type3 ={
				"cls_no": 3,
				"cls_name": "Jewelrires",
				"cls_cn": "CH饰品",
				"pid": 0,
				"like_id": 0,
				"is_show": 1,
				"DeletedAt": "0001-01-01T00:00:00Z",
				"children": null,
				"has_goods_num": 9999,
				"sub": {}
			}
      var temp = JSON.parse(JSON.stringify(this.$refs.WPheader.goodsTypeTree))
      temp[3]=type3
      this.$refs.WPheader.goodsTypeTree =temp
      // console.log(this.$refs.WPheader.goodsTypeTree)
    },
  },
};
</script>
